import axios from 'axios';
// import { isauthenticated } from '../../../../crm-fta/app/controllers/usersController';

const RESSOURCE_NAME = 'users';

export default {
    login (user){
        return axios.post('users/login', {email:user.email, password: user.password})
    },
    getProjets(idUser = null, size = process.env.VUE_APP_NB_DISPLAY, page = 0, order = 'date_projet' ,sort="DESC",query=""){
        return axios.post(`${RESSOURCE_NAME}/ActiveProjets`+(idUser?'/'+idUser:'')+`?page=${page}&size=${size}&orderBy=${order}&sortBy=${sort}`,{query:query});
            // '/ users / ActiveProjets ? page = 0 & size=1 & orderBy=nom_projet & sortBy=DESC'
    },
    getUserRappels(idUser){
        return axios.get(`${RESSOURCE_NAME}/rappels`+(idUser?'/'+idUser:''))
    },
    isauthenticated(){
        return axios.get(`${RESSOURCE_NAME}/isauthenticated` )
    },
    lostPassword(user){
        console.log(user.email,'in api');
        return axios.post(`${RESSOURCE_NAME}/lostpassword`, {email:user.email})
    },
    getUserRetours(idUser,query="",column="date_retour",order="DESC",page=0, size=process.env.VUE_APP_NB_DISPLAY){
        console.log("console de get user", idUser,query)
        return axios.post(`${RESSOURCE_NAME}/retours`+(idUser?'/'+idUser:'')+`?page=`+page+`&size=${size}&orderBy=`+column+`&sortBy=`+order+``,{query:query})
    },
    getCommerciaux(){
        return axios.get(`${RESSOURCE_NAME}/get` )
    },
    getChiffres(idUser){
        return axios.get(`${RESSOURCE_NAME}/chiffres` + (idUser ? '/' + idUser : ''))
    },
    getDevis(idUser = null, size = process.env.VUE_APP_NB_DISPLAY, page = 0, order = 'date_devis' ,sort="DESC",query=""){
        return axios.post(`${RESSOURCE_NAME}/devis`+(idUser?'/'+idUser:'')+`?page=${page}&size=${size}&orderBy=${order}&sortBy=${sort}`,{query:query});
    },                                                                      
   
}