
   <template>
  <div>
      <v-container fluid>
      <HeaderBar/>
      
    </v-container>
  </div>
</template>


<script>
import Header from "@/components/shared/Header";
export default {
  components: {
    HeaderBar: Header,
  },
};
</script>