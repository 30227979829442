<template lang="">
    <v-row class="bandeau_noir">
      <v-col cols="3" offset="9" align="right" class="margin_right">
        <span v-on:click="visible = true"  v-if="!visible">SE CONNECTER EN TANT QUE {{commercial.nom.toUpperCase()}}</span>
        <v-select
          :items="commerciaux"
          item-value="id_utilisateur"
          item-text="nom"
          v-model="selected"
          light
          outlined
          dense
          color="#FFF"
          v-if="visible"
          background-color="#fff"
          clearable
          v-on:change="selectedCommercial"
          hide-details
        >
        
         </v-select>
      </v-col>
    </v-row>
</template>
<script>
import api from '@/api/user'
export default {
  data(){
    return {
      visible: false,
      commerciaux:[],
      selected:'',
      commercial:this.$store.state.AsUser
    }
  },  
  methods: {
    selectedCommercial(){
      this.visible = false;
      // console.log(this.selected)
     this.commercial= this.selected?this.commerciaux.filter(commercial => this.selected == commercial.id_utilisateur)[0]:{nom:'',id_utilisateur:""};
     this.$store.commit('connectedAs',this.commercial)
    //  console.log(this.$store.state.AsUser,'user state',this.$store.state)
     this.$emit('selectedCommercial',{count:1});

      }
   
  },
  created(){
    api.getCommerciaux().then((res) => {
      
      this.commerciaux = res.data.users;
      // console.log(this.commerciaux);
    })
  }
};
</script>
<style scoped>
.bandeau_noir {
  background-color: black;
  color: white;
  text-align: end;
}
.margin_right {
  
  font-size: 0.8em;
}
::v-deep fieldset{
  display: none;
}
</style>
