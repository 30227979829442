import Vue from "vue";
import Vuex from "vuex";
import axios from "axios"
import api from "../src/api/user"

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({

        state: {
            user: {},
        token: localStorage.getItem("user-token") || "",
        AsUser:{nom:'',id_utilisateur:''},
        currentTab: "FICHE CLIENT",
        entreprise : {
            raison_sociale:"",
            actif:true,
            code_fta:""
        }
        },
        mutations:{
            infosUser: (state, resp) => {
                console.log('mutation',resp);
                state.status = "logged";
                // enregistrement du user dans le state
                state.user = resp.user;
                // stocker dans le local storage le token
                localStorage.setItem("user-token", resp.token);
                localStorage.userToken = JSON.stringify(resp.token);
                axios.defaults.headers.common = { 'Authorization': `Bearer ${resp.token}` }
                

            },
            connectedAs(state,user){
                state.AsUser = user

            },
            changeOnglet(state,value){
                state.currentTab = value;
            },
            upadateActifEntreprise(state,value){
                state.entreprise.actif = value
            },
            upadateCodeFtaEntreprise(state,value){
                state.entreprise.code_fta = value
            }
        },
        actions:{
            isAuthenticated:({commit}) => {
                axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem("user-token")}` }
                api.isauthenticated().then(res =>{
                    if(res.status == 200){
                        commit('infosUser', res.data.data)
                    }
                })
            }
        }
      
    })

    
// on peux supprimer pour moi les dossiers actions,modules et creer un dossier api dans src et y mettre le fichier situé dans utils api.js


