import Vue from 'vue'
import App from './App.vue'
import router from './router/routes'
import store from "./../Store";
import '../plugins/axios'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


// vuetify
import vuetify from '../plugins/vuetify' // path to vuetify export
import 'material-design-icons-iconfont/dist/material-design-icons.css'


// layout

import Default from "./layouts/DefaultLayout.vue"
import NoLayout from "./layouts/NoLayout.vue"
import AdminLayout from "./layouts/AdminLayout.vue"


Vue.component('default-layout', Default);
Vue.component('default-admin-layout', AdminLayout);
Vue.component('no-layout', NoLayout);


// moment

import moment from 'moment';
import VueMoment from 'vue-moment';



// leaflet


import 'leaflet/dist/leaflet.css';

import { Icon } from "leaflet";



delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})





require('moment/locale/fr');
moment.locale('fr');

Vue.use(VueMoment, { moment });


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


//directives


import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})

// configuration

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
