<template>
  <v-app id="app">
    <component @selectedCommercial="rechargeComponent" :is="layout"> </component>
    <v-main>
      <v-container id="route" fluid class="px-0" :key="index" :class="{'login':$route.name == 'login'}"> 
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
const defautl_layout = "default";
export default {
  name: "App",
  computed: {
    layout() {
      if (
        this.$store.state.user.Role &&
        this.$store.state.user.Role.id_role == 1 &&
        this.$route.meta.layout != "no"
      ) {
        return (this.$route.meta.layout || defautl_layout) + "-admin-layout";
      } else {
        return (this.$route.meta.layout || defautl_layout) + "-layout";
      }
    },
   
    

  },
  methods :{
     rechargeComponent:function(){
      return this.index +=1;
     
    }
  },
  data(){
    return {
       index:0

    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.v-main,#route{
    height: 87vh;
  }
   #route.login{
    padding: 0;
  }
@media screen and (max-width: 1366px) {
  #app {
    font-size: 14px;
  }
  
}
 
</style>
