import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../../Store'

Vue.use(VueRouter)



const routes = [
  // login
  {
    path: '/',
    name: 'login',
    meta: {
      restriction: [],
      layout : "no",
      title: 'Login',
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Authentification.vue'),

  },
  // home
  {
    path: '/home',
    name: 'home',
    meta: {
      type: 'home',
      title: 'Accueil',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Home.vue'),
  },
  {
    path: '/devis',
    name: 'devis',
    meta: {
      type: 'devis',
      title: 'Devis En Cours',
      Auth:true, // doit être authentifié sur cette route
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Devis.vue'),
  },
  {
    path: '/projet',
    name: 'projet',
    meta: {
      type: 'projet',
      title: 'Projet En Cours',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Projet.vue'),
  },
  {
    path: '/projet_bloc_fore',
    name: 'projet_bloc_fore',
    meta: {
      type: 'projet bloc fore',
      title: 'Projet bloc foré',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ProjetBlocFore.vue'),
  },
  {
    path: '/retoursencours',
    name: 'retoursEnCours',
    meta: {
      title: 'Retour en Cours',
      type: 'retoursEnCours',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/RetoursEnCours.vue'),
  },
  {
    path: '/prets',
    name: 'prets',
    meta: {
      title: 'Prêts',
      type: 'prets',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Prets.vue'),
  },
  {
    path: '/client/:id_entreprise',
    name: 'client',
    meta: {
      title: 'Client',
      type: 'ficheClient',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Client.vue'),
    
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: 'Search',
      type: 'search',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/SearchEntreprise.vue'),
  },
  {
    path: '/prospect',
    name: 'prospect',
    meta: {
      title: 'Prospect',
      type: 'prospect',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Prospect.vue'),
  },
  {
    path: '/client/:id_entreprise/modifier',
    name: 'modifier_client',
    meta: {
      title: 'Modifier Client',
      type: 'modifier_client',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ModifierClient.vue'),
  },
  //reporting
  {
    path: '/report',
    name: 'report',
    meta: {
      title: 'Rapport',
      type: 'rapport',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Reporting.vue'),
  },
  //suivi visites
  {
    path: '/visites',
    name: 'visites',
    meta: {
      title: 'Visites',
      type: 'visites',
      Auth:true, // doit être authentifié sur cette route
     
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Visites.vue'),
  },
  //suivi retards
  {
    path: '/retards_report',
    name: 'retards_report',
    meta: {
      title: 'RetardsReport',
      type: 'retards_report',
      Auth:true, // doit être authentifié sur cette route
      
    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/RetardReport.vue'),
  },

  // // Ajout Projet
  // {
  //   path: '/client/:id_client/projet/nouveau',
  //   name: 'add_projet',
  //   meta: {
  //     type: 'add_projet',
  //     Auth: true, // doit être authentifié sur cette route

  //   },
  //   component: () => import(/* webpackChunkName: "home" */'@/pages/Add_projet.vue'),
  // },

  {
    path: '/client/:id_entreprise/projet/nouveau',
    name: 'create_projet',
    meta: {
      title: 'Créer Projet',
      type: 'create_projet',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ProjetEntreprise.vue'),
  },
  {
    path: '/client/:id_entreprise/projet/:id_projet',
    name: 'view_projet',
    meta: {
      title: 'Voir Projet',
      type: 'view_projet',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ProjetEntreprise.vue'),
  },
  {
    path: '/client/:id_entreprise/projet/:id_projet/modifier',
    name: 'update_projet',
    meta: {
      title: 'Modifier Projet',
      type: 'update_projet',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ProjetEntreprise.vue'),
  },

  {
    path: '/prets/:numero_commande',
    name: 'view_pret',
    meta: {
      title: 'Voir Pret',
      type: 'view_pret',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/PretClient.vue'),
  },

  {
    path: '/prets/:id_entreprise/:numero_commande/modifier',
    name: 'update_pret',
    meta: {
      title: 'Modifier Pret',
      type: 'update_pret',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/PretClient.vue'),
  },

  {
    path: '/client/:id_entreprise/retour/nouveau',
    name: 'create_retour',
    meta: {
      title: 'Créer Retour',
      type: 'create_retour',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/RetourEntreprise.vue'),
  },

  {
    path: '/client/:id_entreprise/:id_retour',
    name: 'view_retour',
    meta: {
      title: 'Voir Retour',
      type: 'view_retour',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/RetourEntreprise.vue'),
  },

  {
    path: '/client/:id_entreprise/:id_retour/modifier',
    name: 'update_retour',
    meta: {
      title: 'Modifier Retour',
      type: 'update_retour',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/RetourEntreprise.vue'),
  },

  {
    path: '/client/:id_entreprise/rapport/nouveau',
    name: 'add_rapport',
    meta: {
      title: 'Ajouter Rapport',
      type: 'add_rapport',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Add_rapport.vue'),
  },
  {
    path: '/client/:id_entreprise/rapport/:id_rapport/modifier',
    name: 'update_rapport',
    meta: {
      title: 'Modifier Rapport',
      type: 'update_rapport',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Add_rapport.vue'),
  },
  {
    path: '/client/:id_entreprise/rapport/:id_rapport',
    name: 'view_rapport',
    meta: {
      title: 'Voir Rapport',
      type: 'view_rapport',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Add_rapport.vue'),
  },
  {
    path: '/client/:id_entreprise/contact/nouveau',
    name: 'new_contact',
    meta: {
      title: 'Nouveau Contact',
      type: 'new_contact',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ContactEntreprise.vue'),
  },
  {
    path: '/client/:id_entreprise/contact/:id_contact/modifier',
    name: 'update_contact',
    meta: {
      title: 'Modifier Contact',
      type: 'update_contact',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/ContactEntreprise.vue'),
  },

  {
    path: '/litiges',
    name: 'litiges',
    meta: {
      title: 'Litiges',
      type: 'litiges',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Litiges.vue'),
  },
  {
    path: '/litige/:numero_commande',
    name: 'view_litige',
    meta: {
      title: 'Voir Litige',
      type: 'view_litiges',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/components/Commandes/Litiges.vue'),
  },
  {
    path: '/litige/:numero_commande/modifier',
    name: 'update_litige',
    meta: {
      title: 'Modifier Litige',
      type: 'update_litige',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/components/Commandes/Litiges.vue'),
  },
  {
    path: '/retards',
    name: 'retards',
    meta: {
      title: 'Retards',
      type: 'retards',
      Auth: true, // doit être authentifié sur cette route

    },
    component: () => import(/* webpackChunkName: "home" */'@/pages/Retards.vue'),
  },

  {
    path: '/404',
    name: 'page not found',
    component: () => import(/* webpackChunkName: "NotFound" */'@/pages/NotFound.vue'),    
    meta: { 
      transitionName: 'slide',
      title: 'Page Introuvable',
    },
  },
  // route à laisser à la fin pour gérer les routes n'existant pas
  {
    path: '*',
    redirect: '/404'
  },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// this function looks for an item in the route object to determine that a route is auth protected
// meta: { Auth: true }
router.beforeEach((to, from, next) => {

  // PERSONNALISER LE TITLE AVEC CELUI DONNÉ DANS routes.js meta.title

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  if (to.matched.some(record => record.meta.Auth)) {
    if (!localStorage.getItem("user-token")) {
      next({
        name: 'login',
      })
    } else {
      store.dispatch("isAuthenticated");
      next()
    }
  } else if (localStorage.getItem("user-token")){
    store.dispatch("isAuthenticated");
    next()
  }
  
  else {
    next()
  }
})




export default router
