<template>
    <v-container fluid >
      <LogOn @selectedCommercial="$emit('selectedCommercial')"></LogOn>
      <HeaderBar  ></HeaderBar>
      
    </v-container>
</template>

<script>
import Header from "@/components/shared/Header";
import LogOn from "@/components/shared/AdminHeader.vue";
export default {
  components: {
    HeaderBar: Header,
    LogOn,
  },
};
</script>
<style>
.container.container--fluid.pa-0{
 height: 100%;
}
</style>